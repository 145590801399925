const ACCOUNTS_TO_NOTIFY = [
  4141, //Btshops
  4145, //deVentas.com
  4276, //TELAVENDO.COM.AR S.A.
  1206, //Santaclara
  1205, //Juanita Jo
  1774, //URBAN COW
  5461, //BECCA
  4916, //Style Store
  2571, //maleducadas
  685, //Odisea Swimwear
  4675, //Dvigi SA
  3954, //Ciudadela // XPIRIT // DFAC
  2134, //Peter Pan
  3498, //Underwave Brand
  1929, //LUXO
  6694, //Grupo Editorial Planeta SAIC
  4475, //UNDF SRL
  2929, //Socsav SA
  3095, //R.A.D denim
  4420, //A Nation
  9855, //Moraco
  9213, //Maria Cher
  9148, //Batuk
  8355, //CALZADOS BLANCO
  1385, //Andressa & The Sunshine Club
  52, //El Mundo del Juguete
  7092, //FARMAONLINE
  3043, //Salpa
  1214, //VCP
  4306, //Las Rishis
  7789, //TelaEntrego
  4197, //MMBI Bikinis
  1568, //Anastasia Monaco
  5289, //Laddubar / Sri Sri Tattva
  9371, //Salve Regina
  4122, //Caseland
  1963, //URBAN BRAND
  4640, //MySaint
  2706, //DAPOP
  4065, //REVOLVER
  4550, //Buhl S.A
  4480, //Ombak
  4454, //Vinilo Design
  4007, //Biohelper
  6571, //Hey Jules
  4544, //Inti Tea
  5327, //Beyond STW
  4320, //Fuego Tostadores
  682, //dollStore/dsMen*
  1074, //Alfonsina fal
  8169, //Trown Outdoor
  4369, //Dolores Iguacel
  3048, //Oxford Polo Club
  4410, //Conyntra Fine Food
  10766, //Duvet Home
  1172, //Santa Bohemia
  39, // Test tecnología
];
export const notifyAccount = (id) => {
  return ACCOUNTS_TO_NOTIFY.includes(id);
};
